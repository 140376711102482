<header class="text-gray-600 body-font">
  <div class="container mx-auto flex flex-wrap pb-8 pt-8 pl-2 pr-2 md:p-4 flex-col md:flex-row items-center">
    <a routerLink="/" class="flex title-font font-medium items-center text-gray-900 mb-8 md:mb-0">
      <img src="/assets/img/logo_header.png" width="120" height="60" alt="acabjj logo">
    </a>
    <nav class="md:ml-auto flex flex-wrap items-center text-md font-bold justify-center">
      <a routerLink="/gallery" class="m-3 hover:text-gray-900">Gallerie</a>
      <a routerLink="/agenda" class="m-3 hover:text-gray-900">Agenda</a>
      <a routerLink="/prices" class="m-3 hover:text-gray-900">Preise</a>
      <a routerLink="/contact" class="m-3 hover:text-gray-900">Kontakt</a>
    </nav>
  </div>
</header>
